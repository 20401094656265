<template>
  <div id="section-content" class="container container-fluid">
    <div class="bar-back">
      <router-link to="/member/main"><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link>
    </div>
    <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 140px) - 50px)">
      <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0">
        <div class="lotto-title">
          <h4><i class="fas fa-ban"></i> เกิดข้อผิดพลาด</h4>
        </div>
      </div>
      <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 xtarget col-lotto">
        <div class="text-center my-4">
          <span style="font-size: 100px; line-height: 1.2"
            ><i class="fas fa-exclamation-triangle text-warning"></i
          ></span>
          <h4 class="text-primary" style="font-family: inherit">ท่านไม่สามารถถอนเงินได้</h4>
          <span>*เนื่องจากยอดแทงของท่านไม่ถึงตามที่กำหนด</span>
        </div>
        <hr />
        <div class="text-center my-4 d-flex flex-column justify-content-center align-items-center">
          <h4 class="text-primary" style="font-family: inherit">{{ creditTurn }}</h4>
          <!-- <div class="border border-secondary rounded table-secondary text-primary p-2 m-0" style="width: fit-content"> -->
            <!-- <h2 class="mb-0" style="font-family: inherit; width: fit-content">{{ Number(creditTurn).toLocaleString('en') }} บาท</h2> -->
          <!-- </div> -->
          <span>*ท่านจึงจะสามารถถอนเงินออกจากระบบได้</span>
        </div>
      </div>
      <div class="bg-white p-2 rounded shadow-sm w-100 mb-5">
        <router-link to="/member/lottery" tag="button" class="btn btn-primary btn-block text-white">
          แทงหวย <i class="fas fa-chevron-right"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      creditTurn: state => state.bankModule.creditTurn
    })
  }
}
</script>

<style>
</style>
